import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
    display: inline-block;
    width: 90%;
    height: 1px;
    background: #CCCCCC;
    margin: auto;
`

const MobileHorizontalLine = () => {

    return (
        <Root />
    )
}

export default MobileHorizontalLine
