import React from "react"
import { graphql } from "gatsby"
import CompanyTemplate from "../components/templates/CompanyTemplate"
import "../layouts/style.css"

const CompanyPage = ({ data }) => {

  const quandoMemberPosts = []
  data.allMarkdownRemark.edges.forEach((post) => {
    const frontmatter = post.node.frontmatter
    const slug = frontmatter.slug.match(/([a-z]{6})(-)([0-9]*)/)
    const postData = {
      name: frontmatter.slug,
      id: (slug === null) ? -1 : slug[3],
      thumbnailPath: frontmatter.thumbnail,
      position: frontmatter.position,
      order: frontmatter.order,
      jpName: frontmatter.jpName,
      enName: frontmatter.enName,
    }
    if (postData.position !== null) {
      quandoMemberPosts.push(postData)
    }
  })
  quandoMemberPosts.sort((a, b) => {
    if (a.order <= b.order) {
      return -1
    } else {
      return 1
    }
  })

  return (
    <CompanyTemplate posts={quandoMemberPosts} />
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            thumbnail
            position
            order
            jpName
            enName
          }
          excerpt
        }
      }
    }
  }
`

export default CompanyPage