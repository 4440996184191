import React from "react"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Header from "../PC/organisms/Header"
import Footer from "../PC/organisms/Footer"
import CompanyMembers from "../PC/organisms/CompanyMembers"
import CompanyInfo from "../PC/organisms/CompanyInfo"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobileCompanyInfo from "../mobile/organisms/MobileCompanyInfo"
import MobileCompanyMembers from "../mobile/organisms/MobileCompanyMembers"
import judgePage from "../judgePage"
import VisionCatchcopy from "../PC/molecules/VisionCatchcopy"
import MobileVisionCatchcopy from "../mobile/molecules/MobileVisionCatchCopy"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileFooter from "../mobile/organisms/MobileFooter"
import MobileHero from "../mobile/organisms/MobileHero"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: auto;
`
const CompanySEOData = {
    title: 'カンパニー',
    lang: 'jp',
    url: '/company',
    description:'会社案内',
}


const CompanyTemplate = ({ posts }) => {

    const page = judgePage('COMPANY')
    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={CompanySEOData.title}
                lang={CompanySEOData.lang}
                url={CompanySEOData.url}
                description={CompanySEOData.description}
            />
            {breakPoints.pc
                ?
                    <React.Fragment>
                        <Hero page={page.name} />
                        <VisionCatchcopy />
                        <CompanyInfo page={page.name} />
                        <CompanyMembers posts={posts} />
                        <Footer />
                        <Header page={page.name} />
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobile
                ?
                <React.Fragment>
                        <MobileHero page={page.name} />
                        <MobileVisionCatchcopy />
                        <MobileCompanyInfo page={page.name} />
                        <MobileCompanyMembers posts={posts} />
                        <MobileFooter />
                        <MobileHeader />
                    </React.Fragment>
                :
                    null
            }
        </Template>
    )
}

export default CompanyTemplate